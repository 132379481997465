import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function ShareCapital() {
  /* Slider */
  // const ShareCapSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Change in Share Capital`,
  //     content: `Advisory Service For Change in Share Capital`,
  //     image: "/imgs/registration/msme/ms-me.png",
  //     alt_tag: "Best Advisory Change In Share Capital In Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Change In Share Capital`,
    buyBtnLink: `#pricing-buy`,
    price: `3499`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const ShareCapAboutData = {
    header: `Change in Share Capital`,
    sub_title: `Advisory service for change in share capital`,
    content_paragraph: [
      //   `One of the most important considerations that the supporters
      // must make with great attention is the quantity of investment
      // used as money to launch a company.`,

      //   `The Company may discover
      // a means to extend its company operations, whether in terms
      // of scale, size, or structure, when the industry begins to
      // pick up.`,

      `The significant investment amount where the company can
    exercise the option to issue shares is known as the
    authorized capital. The Issued Capital Limit is stated in
    the Capital Clause of the Company's Memorandum of
    Association, in accordance with Section 2(8) of the
    Companies Act of 2013.`,
    ],
  };

  /* Scroll Nav Data */
  const ShareCapScrollId = [
    {
      id: `#share-capital`,
      heading: `Share Capital`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#documentss`,
      heading: `Document`,
    },
    {
      id: `#characteristics`,
      heading: `Characteristics`,
    },
  ];

  /* Img Content Component Data */
  const ShareCapIcData = {
    id: 'share-capital',
    mt_div: 'mt-5',
    mt_img: 'mt-2',
    header: 'What Share Capital Means?',
    image: '/imgs/registration/dsc/dsc-img-slider.png',
    alt_tag: 'Share Capital',
    Paragraph: '',
    points: [
      {
        content: `The company's "Share Capital," which is the sum
      divided into a predetermined number of shares or a
      predetermined amount of shares. To continue operating,
      any company needs cash in the form of share capital.
      The business uses cash to carry out its essential
      operations to gain in commercial real estate,
      inventory, and other assets.`,
        icon: true,
      },
      {
        content: `Present Approved Share Capital must be
      checked first if it decides to increase its Capital.
      It's because, in order to issue the shares, the
      Company must raise the approved share capital by
      amending the MOA. The Company is not permitted to
      grant shares beyond the authorized share capital in
      any way.`,
        icon: true,
      },
      {
        content: `If approved by the Articles of Association, the
      Company that owns Share Capital may change its Share
      Capital. The Companies Act of 2013 mandates that the
      Company adhere to certain procedures. It is necessary
      to seek approval by submitting the necessary papers
      before the company's share capital is increased or
      changed.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ShareCapCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Procedure for change share capital',
    content: ``,
    body: [
      {
        head: 'Method to Modify Authorized Capital',
        points: `Share capital change step by step procedures are,`,
        icon: true,
      },
      {
        points: `Read the Articles of Association carefully`,
        icon: true,
      },
      {
        points: `Board meeting will be held. `,
        icon: true,
      },
      {
        head: "Extraordinary General Meeting's",
        points: `The issue of the increase in share capital is brought
      forward following the meeting. The voting procedure is
      then carried out according to a predetermined
      procedure to bring the share capital issue to a close.`,
        icon: true,
      },
      {
        points: `Following approval and authorization of the
      resolution, the explanatory report is attached and the
      share capital is increased.`,
        icon: true,
      },
      {
        head: 'Filing with the Registrar of Companies',
        points: `A corporation must submit eForm SH-7 and eForm MGT -
      14 (if applicable), along with all required paperwork
      and the required fees, to the Registrar within 30 days
      of the resolution's approval.`,
        icon: true,
      },
      {
        head: 'Form MGT – 14:',
        points: `Within 30 days of the increase in share capital
      resolution's passage, this form must be presented to
      the RoC.`,
        icon: true,
      },
      {
        head: 'Form SH – 7:',
        points: `The main goal of this form is to provide suggestions
      to the Registrar on information about the company's
      increase in authorized capital. According to the RoC
      within 30 days, the forms must be submitted within the
      allotted time frame to avoid penalties or further
      penalty.`,
        icon: true,
      },
    ],
  };

  /* Img Slider */
  const imgSlider = [
    '/imgs/registration/dsc/dsc-doc.png',
    '/imgs/registration/msme/msme-reg.png',
    '/imgs/registration/dsc/dsc-significant.png',
  ];

  /*  Slider Content Component Data */
  const ShareCapScData = {
    id: 'documentss',
    background: [],
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: 'Documents Required for Share Capital Changes',
    paragraph: '',
    content: [
      {
        points: `Copy of the resolution approved at the associated with
        Modified AOA general meeting.`,
        icon: true,
      },
      {
        points: `Certificate as evidence of new capital arrangement and
        newly introduced class of shares that has been
        combined or divided.`,
        icon: true,
      },
      {
        points: `Copies of the last three years' worth of audited
        balance sheets and bank payment of the
        request fee. .`,
        icon: true,
      },
      {
        points: `Issuance copy of the Board's decision to modify the
        AOA and MOA.`,
        icon: true,
      },
      {
        points: `Authenticating legal papers for the petition and true legal document of the shareholders.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /*  Content Img Component Data */
  const ShareCapCiData = {
    id: 'characteristics',
    background: 'main-content',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Characteristics of Share Capital Change',
    paragraph: ``,
    points: [
      {
        head: 'Sale of Goods Act, 1930',
        content: `According to the Sale of Goods Act of 1930, "goods"
        refers to any transportable property that is not cash,
        stock, or other exceptional instances.`,
        icon: true,
      },
      {
        content: `The term "share capital" refers to the sole ownership
        of a predetermined amount of share capital, holding
        with a set of distinct rights and financial
        obligations.`,
        icon: true,
      },
      {
        content: `Additionally, any share of a corporation that is
        movable property and is transferrable in the manner
        specified in the company's AOA.`,
        icon: true,
      },
      {
        head: 'Companies Act, 2013',
        content: `The Companies Act of 2013's Section 61 outlines the
        various types of Change in Share Capital and their
        relationships.`,
        icon: true,
      },
      {
        content: `Combining and dividing the entire share capital into
        shares with larger nominal values than the current shares.`,
        icon: true,
      },
      {
        content: `Convert all or a portion of the company's fully
        paid-up shares into stock, and then convert that stock
        back into any division's fully paid-up portions.`,
        icon: true,
      },
    ],

    image: '/imgs/registration/iso/iso-img-slider.png',
    alt_tag: 'Share Capital Change',
  };

  var FAQ_data = [
    {
      header: `What does "Change in Share Capital" mean?`,
      body: [
        {
          content: `Modification of the number of shares corresponds to a change in
              the share capital.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Can the initial share capital be raised or altered?',
      body: [
        {
          content: `A firm can increase its share capital by issuing the Right Issue
              of shares if it wants to do so. After the organization has passed
              a special resolution, the right Issue may be made available to
              current promoters or investors under a plan of employers'
              investment opportunity.`,
          icon: false,
        },
      ],
    },
    {
      header: 'The capital is approved in what way?',
      body: [
        {
          content: `rise or shift in Share The structure of an organization must be
              changed, and capital is crucial. Redesigning or restyling capital
              may involve changing the share capital. Shares may be combined to
              accomplish this, or the par value of the shares may be lowered.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How is cash raised in the primary market?',
      body: [
        {
          content: `Business can raise funds from the main market using a number of
              strategies. Some of them encourage tender processes, right Issues,
              private placements, offers for sale, and public issues.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a firm utilize or spend the capital from its shares?',
      body: [
        {
          content: `Company may use its share premium account or capital redemption
              reserve to fund a business issue of fully or partially paid-up
              bonus shares in proportion to their existing shareholdings, if
              permitted by its articles of incorporation or AOA.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'In which clause of the MOA the authorized share capital is mentioned?',
      body: [
        {
          content: `The authorized share capital of the Company is mentioned in Clause V of the MOA.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Which forms are required for increasing the authorized share capital of the Company?',
      body: [
        {
          content: `Form MGT 14 and SH 7 are required to be filed with the Registrar within 30 days 
          from the date of passing the resolution for the increase in authorized share capital.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What is the procedure for increasing the authorized share capital?',
      body: [
        {
          content: `Verify the AOA Convene Board meeting Extraordinary general meeting File ROC forms Allotment of shares.`,
          icon: false,
        },
      ],
    },
    {
      header: ' When should the authorized share capital increase?',
      body: [
        {
          content: `A company is required to increase the authorized share capital before issuing the new equity shares and 
          increasing the paid-up capital. The Authorised share capital is the total value of the shares a company can issue.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the minimum authorized shared capital?',
      body: [
        {
          content: `Private Limited Companies are required to have a minimum authorized share capital of 
            Rs.1 lakh and Rs.5 lakh for public limited companies`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Change in Share Capital'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ShareCapSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ShareCapAboutData} />
          <Counter />
          <ScrollNav scroll_data={ShareCapScrollId} />
          <ImgContent item={ShareCapIcData} />
          <ContentForm CFSection_data={ShareCapCfData} />
          <SliderContent ScSection_data={ShareCapScData} />

          <ContentImg CISection_data={ShareCapCiData} />
          <Cta />

          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
